<template>
  <a-config-provider :locale="locale">
    <template #renderEmpty>
      <a-empty description="暂无数据"></a-empty>
    </template>
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  watch: {
    $route: {
      handler(to) {
        let name = to.name;

        if (to.meta.parent) {
          name = to.meta.parent;
        }
        if (name === "CommonDataList" || name === "AppraisalNotification") {
          name = name + "#type:" + to.params.type;
        }
        if (to.params.parent) {
          name = to.params.parent;
        }
        if (name) {
          if (name === "Notification") {
            this.$store.state.selectedTopMenuId = "";
            return;
          }
          const menuList = this.$store.state.userMenu;
          menuList.forEach((item) => {
            if (item.path === name) {
              this.$store.state.selectedTopMenuId = item.menuId;
            } else {
              if (item.subMenus) {
                item.subMenus.forEach((sub) => {
                  if (sub.path === name) {
                    this.$store.state.selectedTopMenuId = item.menuId;
                    this.$store.dispatch("setCurSubMenuList", item.subMenus);
                    this.$store.state.selectedSubmenuId = sub.menuId;
                  } else if (sub.subMenus) {
                    sub.subMenus.forEach((subItem) => {
                      if (subItem.path === name) {
                        this.$store.state.selectedTopMenuId = item.menuId;
                        this.$store.dispatch(
                          "setCurSubMenuList",
                          item.subMenus
                        );
                        this.$store.state.selectedSubmenuId = subItem.menuId;
                      }
                    });
                  }
                });
              }
            }
          });
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
};
</script>

<style>
.side-menu-container {
  width: 400px;
  background-color: #fff;
  z-index: 1000;
}
.main-container {
  padding: 0 24px 24px;
  min-height: 88vh !important;
  margin-top: 67px;
}
.content-container {
  background-color: #fff;
  min-height: 600px;
  min-width: 1000px;
  font-size: 14px;
}
.side-menu-container {
  margin-top: 64px;
}
/deep/.data-table {
  width: 98%;
  margin: 20px auto;
}
.search-box {
  width: 260px;
}
.data-table {
  margin-top: 20px;
}
#first-search-item {
  padding-left: 20px !important;
}
.ant-form-item-label > label {
  margin-left: 0px !important;
}
.ant-page-header {
  padding-left: 0 !important;
}
#search-form-btns {
  margin-top: 4px;
}
.table-container {
  min-height: calc(100vh - 258px);
}
.search-result-list {
  margin-top: 16px;
}
.layer-icon {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}
.layer-name {
  margin-left: 15px;
  margin-top: 4px;
}
.del-attachment {
  margin-left: 4px;
}
.leaflet-control-layers-selector {
  display: none;
}
.comp-type-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 4px;
}
.layer-icon {
  border: 1px #ababab solid;
  padding: 2px;
}
.layer-name {
  color: #ababab;
}
.leaflet-control-layers-overlays {
  padding-top: 12px;
}

.popup-tbl td {
  border: 0px;
}
.popup-tbl {
  font-size: 13px;
  line-height: 24px;
  width: 320px;
}
.popup-title {
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}
.popup-title-image {
  font-size: 14px;
  line-height: 25px;
  width: 100%;
}
.detail {
  display: flex;
  justify-content: center;
}

.popup-tbl tr td:first-child {
  font-weight: bold;
  font-size: 14px;
  min-width: 50px;
  padding-left: 13px;
  padding-right: 13px;
}
.dates-link {
  margin-right: 20px;
  cursor: pointer;
}
.active-link {
  color: #1890ff;
}
.ant-form-item-label {
  text-align: left !important;
  color: #646a73 !important;
}
.ant-form-item label {
  color: #646a73 !important;
}
.ant-form-item-label > label {
  color: #646a73;
}
.ant-page-header-heading-title {
  color: #1f2329 !important;
}
.ant-select {
  color: #646a73 !important;
}
.ant-table-thead > tr > th {
  color: #1f2329 !important;
}
.ant-table-tbody > tr > td {
  color: #646a73;
}
.btn--reset {
  color: #1f2329 !important;
}
.btn--export {
  color: #1f2329 !important;
}
.ant-table-footer .total .total__item {
  color: #1f2329;
}
.ant-btn-primary {
  color: white !important;
}
.ant-input {
  color: #646a73 !important;
}
.ant-modal-title {
  color: #1f2329;
}

.ant-modal-footer .ant-btn {
  color: #1f2329 !important;
}
.ant-modal-footer .ant-btn-primary {
  color: white !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
}
/*color:#1f2329
    color:#646a73
    color:#8f959e
  */
.search-row {
  margin-top: 8px;
}
.btn-reset {
  margin-left: 0 !important;
}
.reset-button {
  left: 0 !important;
}
.advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
.btn-new {
  margin-bottom: 24px;
}
.btn-output {
  margin: 0 16px;
}
.operation-btn {
  display: flex;
  box-sizing: border-box;
  padding: 0 0 24px 0;
  align-items: center;
}
.menu-container-item {
  font-size: 14px;
}
.btn-add {
  margin-bottom: 20px;
}
.btn-search {
  margin-top: 4px;
}
.btn-reset {
  margin-left: 20px;
  margin-top: 4px;
}
/*表格行高、字体*/
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 9px !important;
  font-size: 14px !important;
}
/*表格header颜色*/
.ant-table-thead tr th {
  background-color: rgb(247, 248, 250) !important;
}
/*按钮字体*/
.ant-btn {
  font-size: 14px !important;
}
/*form表单字体*/
.ant-form-item {
  font-size: 14px !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgb(243, 244, 246);
  transition: background 0.3s;
}
.ant-form label {
  font-size: 14px !important;
}
/*操作字体颜色*/
.action-text-color {
  color: #1890ff !important;
}
/*删除字体颜色*/
.delete {
  color: red !important;
  cursor: pointer;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
/*a-space距离*/
.ant-space-item {
  margin-right: 8px !important;
}
/*ant-layout背景颜色*/
.ant-layout {
  background-color: rgb(245, 247, 254) !important;
}
body {
  background-color: rgb(245, 247, 254) !important;
}
/*card阴影*/
.card-shadow {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
/*搜索内容区*/
.search__container {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #14b672 !important;
  background-color: rgba(20, 182, 114, 0.6) !important;
}
/*表格内容区*/
.table__container {
  box-sizing: border-box;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
</style>
