import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  //数据
  state: {
    userMenu: [],
    hasChange: false, //进入子页面修改成功后，这个值会影响父页面发起搜索
    record: {},
    month: [],
    secondMonth: [],
    thirdlyMonth: [],
    evaluation: {},
    queryDates: [],
    curSubmenus: [],
    selectedTopMenuId: "",
    selectedSubmenuId: "",
    useTreeMap: false,
    loadGovernLevel: "",
    mapData: {
      //地图中心
      mapCenter: {
        lat: "",
        lgt: "",
      },
      //行政区信息
      area: {},
      //巡河记录
      patrolRecordList: {},
      //河流列表
      riverList: [],
      //河道列表
      reachList: [],
    },
  },
  //修改数据
  mutations: {
    SET_USER_MENU(state, menus) {
      state.userMenu = menus;
    },
    SET_CUR_SUB_MENU(state, subMenus) {
      state.curSubmenus = subMenus;
    },
    SET_CUR_TOP_MENU_KEY(state, menuKey) {
      state.selectedTopMenuId = menuKey;
    },
    SET_CUR_SUB_MENU_KEY(state, menuKey) {
      state.selectedSubmenuId = menuKey;
    },
  },
  actions: {
    setUserMenu({ commit }, menus) {
      commit("SET_USER_MENU", menus);
    },
    setCurSubMenuList({ commit }, subMenus) {
      commit("SET_CUR_SUB_MENU", subMenus);
      if (!subMenus) {
        commit("SET_CUR_SUB_MENU_KEY", "");
      } else if (subMenus.length > 0) {
        commit("SET_CUR_SUB_MENU_KEY", subMenus[0].menuId);
      }
    },
    setCurTopMenuKey({ commit }, menuId) {
      commit("SET_CUR_TOP_MENU_KEY", menuId);
    },
    setCurSubMenu({ commit }, subMenuId) {
      commit("SET_CUR_SUB_MENU_KEY", subMenuId);
    },
    logoutUser({ commit }) {
      commit("SET_USER_MENU", []);
      commit("SET_CUR_SUB_MENU", []);
      commit("SET_CUR_TOP_MENU_KEY", "");
      commit("SET_CUR_SUB_MENU_KEY", "");
    },
  },
  getters: {},
  plugins: [createPersistedState()],
});
